.navbarLight {
  color: var(--dark);
  font-family: var(--font-2);
  background: #fff;
  -webkit-box-shadow: 0 3px 7px rgb(0 0 0 / 6%);
  box-shadow: 0 3px 7px rgb(0 0 0 / 6%);
}

.navbarLight .navItem {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}
