.verify {
  padding-top: 60px;
  padding-bottom: 60px;
}

.verifyTitle {
  font-size: 40px;
  text-align: center;
  color: var(--dark);
}

.verifyTitle span {
  color: var(--brand);
}

@media (max-width: 575.98px) {
  .verifyTitle {
    font-size: 1.5rem;
  }
}
