header {
  padding-top: 8rem;
  padding-bottom: 15rem;
}

header h2 {
  margin-bottom: 1rem;
  font-size: 3.5rem;
}

header h2 span {
  color: var(--dark);
}

header h3 {
  margin: 1rem 0;
  color: var(--dark);
  font-size: 2.2rem;
}

.headerBg {
  background-image: url("./img/bg.png");
  background-color: var(--light);
  background-blend-mode: multiply;
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 20%;
  position: relative;
}

.bg1 {
  background-image: url("./img/bg-1.svg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.headerText {
  z-index: 1;
}

header .dot {
  position: absolute;
  bottom: -30px;
  right: 0;
  width: 120px;
  height: auto;
  opacity: 0.5;
}

.jobReady {
  font-family: var(--font-3);
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.2rem;
  text-transform: uppercase;
  text-align: center;
  position: absolute;
  left: 80px;
  bottom: -30px;
  height: 160px;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: var(--brand);
  background: linear-gradient(0deg, var(--brand) 0%, #7ed321 100%);
  border: 8px solid #fff;
  border-radius: 100%;
  z-index: 3;
}

@media (max-width: 767.98px) {
  header {
    padding-top: 4rem;
    padding-bottom: 12rem;
  }
  header h3 {
    font-size: 1.5rem;
  }
  header h2 {
    font-size: 2.5rem;
  }
  header .photo {
    max-width: 350px;
  }
  .jobReady {
    height: 120px;
    width: 120px;
    border-width: 5px;
    font-size: 1rem;
    line-height: 1rem;
  }
}

@media (max-width: 575.98px) {
  header {
    padding-top: 2rem;
    padding-bottom: 8rem;
  }
  header h3 {
    font-size: 1rem;
  }
  header h2 {
    font-size: 1.5rem;
  }
  header .photo {
    max-width: 250px;
  }
  .jobReady {
    height: 80px;
    width: 80px;
    border-width: 3px;
    font-size: 0.6rem;
    line-height: 0.6rem;
  }
  header .dot {
    width: 60px;
  }
}
