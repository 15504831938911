@font-face {
  font-family: "SolaimanLipi";
  font-display: swap;
  font-style: normal;
  src: url("../fonts/SolaimanLipi.eot"); /* IE9*/
  src: url("../fonts/SolaimanLipi.eot?#iefix") format("embedded-opentype"),
    url("../fonts/SolaimanLipi.woff") format("woff"),
    url("../fonts/SolaimanLipi.ttf") format("truetype"),
    url("../fonts/SolaimanLipi.svg#SolaimanLipi") format("svg");
}
@font-face {
  font-family: "Hind Siliguri";
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/HindSiliguri-Bold.woff") format("woff"),
    url("../fonts/HindSiliguri-Bold.woff2") format("woff2"),
    url("../fonts/HindSiliguri-Bold.ttf") format("truefype");
}
@font-face {
  font-family: "Hind Siliguri";
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/HindSiliguri-SemiBold.woff") format("woff"),
    url("../fonts/HindSiliguri-SemiBold.woff2") format("woff2"),
    url("../fonts/HindSiliguri-SemiBold.ttf") format("truefype");
}
@font-face {
  font-family: "AkhandBengali";
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/AkhandBengaliBold.woff2") format("woff2"),
    url("../fonts/AkhandBengaliBold.woff") format("woff"),
    url("../fonts/AkhandBengaliBold.otf") format("truetype");
}
@font-face {
  font-family: "AkhandBengali";
  font-display: swap;
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/AkhandBengaliExtrabold.woff2") format("woff2"),
    url("../fonts/AkhandBengaliExtrabold.woff") format("woff"),
    url("../fonts/AkhandBengaliExtrabold.otf") format("truetype");
}
@import url("https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

:root {
  --font-1: "SolaimanLipi", "Roboto";
  --font-2: "Hind Siliguri", "sans-serif";
  --font-3: "Roboto", "SolaimanLipi";
  --light: #e7f8ee;
  --light-1: #f0f8ff;
  --light-2: #faf3ea;
  --light-3: #f6f5fb;
  --brand: #7ab532;
  --cyan: #11a7ca;
  --dark: #212832;
  --gray: #2d373f;
}

body {
  padding: 0;
  margin: 0;
  color: #333333;
  background: #fff;
  font-family: var(--font-1);
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
}

h1,
h2 {
  font-family: var(--font-2);
  font-weight: 700;
  color: var(--brand);
}

h3,
h4,
h5 {
  font-family: var(--font-2);
  font-weight: 600;
}
h3 {
  font-size: 1.5rem;
}
a {
  text-decoration: none;
}

.bg-light {
  background-color: var(--light) !important;
}
.bg-light-1 {
  background: var(--light-1) !important;
}
.bg-light-2 {
  background: var(--light-2) !important;
}
.bg-light-3 {
  background: var(--light-3) !important;
}

.cyan {
  color: var(--cyan) !important;
}

.btn-brand {
  color: #fff;
  background-color: var(--brand);
  border-color: var(--brand);
  font-weight: 700;
}

.btn-brand:hover,
.btn-brand:active,
.btn-brand:focus,
.btn-brand.active {
  background: #749e32;
  color: #ffffff;
  border-color: #749e32;
}

.box-shadow {
  -webkit-box-shadow: 0.3125rem 0.3125rem 2.5rem rgb(0 0 0 / 8%);
  box-shadow: 0.3125rem 0.3125rem 2.5rem rgb(0 0 0 / 8%);
}
.z-index-2 {
  z-index: 2;
  position: relative;
}

.transform-top {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-top: -80px;
}
.link-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.transform-20 {
  transform: translateY(-20%);
  -webkit-transform: translateY(-20%);
  -moz-transform: translateY(-20%);
  -ms-transform: translateY(-20%);
  -o-transform: translateY(-20%);
}

.copyright {
  padding: 0;
  margin: 0;
  color: #ccc;
  background: var(--gray);
}
.copyright a {
  color: var(--brand);
}
.copyright p {
  padding: 20px;
  margin: 0;
  font-family: var(--font-3);
  font-size: 16px;
  text-align: center;
}

.scrollup {
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  font-size: 25px;
  position: fixed;
  bottom: 30px;
  right: 20px;
  display: none;
  background: var(--brand);
}
.scrollup > a {
  position: relative;
  top: 7px;
  color: #fff;
}
