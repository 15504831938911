.joinNow {
  padding: 30px 60px;
  color: #fff;
  background-color: var(--brand);
  background-image: url("./img/join-bg.svg");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1rem;
}

.joinNow h4 {
  padding: 0;
  margin: 0;
  font-size: 35px;
}
.joinNow .btnJoin {
  align-items: center;
  display: flex;
  justify-content: end;
}
.btnJoin a {
  padding-left: 50px;
  padding-right: 50px;
  font-size: 25px;
  font-weight: 700;
  border-radius: 2rem;
}
footer {
  padding-top: 80px;
  padding-bottom: 50px;
  color: #fff;
  background: var(--dark);
}
footer a {
  color: #fff;
}
footer a:hover {
  color: #fff;
}
footer h3 {
  font-size: 25px;
  font-weight: 600;
}
footer ul {
  padding: 0;
  margin: 0;
  margin-bottom: 2rem;
  list-style: none;
}
footer ul li {
  opacity: 0.7;
}

footer ul .location,
footer ul .phone,
footer ul .email {
  padding-left: 35px;
  margin-bottom: 5px;
}

footer ul .location {
  background: url("./img/placeholder.svg") no-repeat top left;
  background-size: 22px 28px;
}

footer ul .phone {
  background: url("./img/phone-call.svg") no-repeat top left;
  background-size: 22px 28px;
}

footer ul .email {
  background: url("./img/email.svg") no-repeat top left;
  background-size: 22px 28px;
  font-family: var(--font-3);
}

@media (max-width: 767.98px) {
  .joinNow h4 {
    font-size: 25px;
  }
  .btnJoin a {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 15px;
    border-radius: 1.5rem;
  }
}

@media (max-width: 575.98px) {
  footer {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .joinNow {
    padding: 20px;
  }
  .joinNow h4 {
    font-size: 20px;
  }
  .btnJoin a {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 10px;
    border-radius: 1rem;
  }
}
