.ourPartner {
  padding-top: 20px;
  padding-bottom: 100px;
  text-align: center;
  background: #fff;
}

.partnerTitle {
  font-size: 40px;
  color: var(--dark);
}

.partnerTitle span {
  color: var(--brand);
}

.partnerLogo {
  padding: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 1rem;
}

.partnerLogo img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 575.98px) {
  .partnerTitle {
    font-size: 1.5rem;
  }
}
