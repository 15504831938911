.itCareer {
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;
}

.careerTitle {
  font-size: 40px;
  color: var(--dark);
}

.careerTitle span {
  color: var(--brand);
}

.careerDecs {
  margin-bottom: 4rem;
}

.career {
  padding: 20px;
  margin: auto;
  margin-bottom: 3rem;
  height: 250px;
  width: 250px;
  background: #fff;
  border-radius: 50%;
  transition: transform 0.3s;
}

.career:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.career p {
  padding: 0 18px;
  margin: 0;
}

@media (max-width: 575.98px) {
  .careerTitle {
    font-size: 1.5rem;
  }
}
