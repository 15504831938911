.newBatch {
  padding-top: 60px;
  padding-bottom: 60px;
}

.batchTitle {
  font-size: 40px;
  color: var(--dark);
}

.batchTitle span {
  color: var(--brand);
}

.item {
  margin-top: 2rem;
  margin-bottom: 2rem;
  background: #fff;
  border-radius: var(--bs-border-radius-xl);
  border: 0.5px solid rgba(0, 0, 0, 0.1);
}

.star {
  font-size: 16px;
  color: #ffae4f;
}

.courseText {
  padding: 20px;
}

.courseText h4 {
  padding: 0;
  margin: 0;
  font-size: 25px;
}

.courseText p {
  padding: 0;
  margin: 0;
}

.courseFee {
  font-weight: 700;
}

@media (max-width: 575.98px) {
  .batchTitle {
    font-size: 1.5rem;
  }
}
