.courses {
  background: #fff;
}

.courseItem {
  padding: 40px 20px;
  margin-bottom: 3rem;
  width: 100%;
  text-align: center;
  background: #fff;
  border-radius: 1.5rem;
}

.courseItem:hover span {
  color: #fff;
}

.courses .icon {
  padding: 0;
  margin: 0 auto;
  width: 50px;
  height: 50px;
}

.courses h3 {
  margin-top: 1rem;
  font-size: 1rem;
}

.courseItem span {
  font-size: 5rem;
  margin-bottom: 2rem;
}

@media (max-width: 767.98px) {
  .courseItem {
    padding: 20px 30px;
    margin-bottom: 1rem;
    border-radius: 1rem;
  }
  .courses .icon {
    width: 50px;
    height: 50px;
  }

  .courses h3 {
    font-size: 1.2rem;
  }
}

@media (max-width: 575.98px) {
  .courses .icon {
    width: 30px;
    height: 30px;
  }
  .courses h3 {
    font-size: 0.8rem;
  }
}
