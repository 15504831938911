.successStory {
  padding-top: 30px;
  padding-bottom: 60px;
  margin: 0;
}

.successTitle {
  font-size: 40px;
  color: var(--dark);
}
.successTitle span {
  color: var(--brand);
}

.successItem {
  padding: 60px;
  margin-top: 1rem;
  margin-bottom: 3rem;
  width: 100%;
  text-align: center;
  background: #fff;
  border-radius: 1.5rem;
  -webkit-border-radius: 1.5rem;
  -moz-border-radius: 1.5rem;
  -ms-border-radius: 1.5rem;
  -o-border-radius: 1.5rem;
}

.successItem h4 {
  font-size: 50px;
  font-family: var(--font-1);
  font-weight: 800;
  color: var(--brand);
}

.storyTitle {
  font-size: 40px;
  color: var(--brand);
}
.story {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.story .desc {
  padding: 0;
  margin: 0;
}
.story hr {
  color: var(--cyan);
  border-top: 3px solid;
  opacity: 0.5;
  width: 40%;
}
.star {
  font-size: 16px;
  color: #ffae4f;
}

.dots {
  position: absolute;
  width: 120px;
  right: 40px;
  bottom: -20px;
  opacity: 0.7;
}

@media (max-width: 575.98px) {
  .successTitle {
    font-size: 1.5rem;
  }
  .successItem {
    padding: 10px;
    border-radius: 1rem;
    margin-bottom: 1rem;
  }
  .successItem h4 {
    font-size: 30px;
  }
  .successItem p {
    font-size: 1rem;
    line-height: 1.3rem;
  }
  .dots {
    width: 60px;
  }
  .story {
    padding-right: 0rem;
    padding-left: 0rem;
  }
}
