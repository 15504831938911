.practicalProject {
  padding-top: 50px;
  padding-bottom: 50px;
}
.custom {
  padding: 3rem;
}
.text {
  padding-left: 2rem;
}
.practicalProject .desc {
  padding-top: 0;
  text-align: justify;
}

.practicalProject .dots {
  position: absolute;
  width: 120px;
  right: -20px;
  top: -45px;
  opacity: 0.7;
}

.projectTitle {
  font-size: 35px;
  color: var(--dark);
}

.projectTitle span {
  color: var(--brand);
}

@media (max-width: 575.98px) {
  .practicalProject {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .custom {
    padding: 1rem;
  }
  .text {
    padding-left: 1rem;
  }
  .projectTitle {
    font-size: 20px;
    margin-top: 1rem !important;
  }

  .practicalProject .dots {
    width: 60px;
    right: 20px;
    top: 30px;
  }
}
