.topbar {
  padding: 0.5rem;
  font-family: var(--font-3);
  font-size: 1.2rem;
  color: #fff;
  background: var(--brand);
}
.topbar a,
.topbar a:hover {
  color: #fff;
}
